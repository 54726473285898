import React from 'react'
import styled, { css } from 'styled-components'

import { Title } from './title'
import { breakpoint } from '../config'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 38px; /* 20px plus 0.425em of negative margin of title */

  @media ${breakpoint.tablet} {
    margin-bottom: 82px;

    ${(props) =>
      props.withIcon &&
      css`
        padding-right: 180px;
      `}
  }
`

const StyledTitle = styled(Title)`
  position: relative;
  bottom: -0.4em;
`

const Icon = styled.div`
  display: none;

  @media ${breakpoint.tablet} {
    display: block;
    margin-left: auto;
    padding-left: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

const IconImg = styled.img`
  display: block;
  width: 160px;
  height: auto;

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100%;
  }
`

export function Intro({ children, icon }) {
  const withIcon = !!icon
  return (
    <Wrapper withIcon={withIcon} id="intro">
      <StyledTitle>{children}</StyledTitle>
      <Icon>
        <IconImg src={icon} alt="" />
      </Icon>
    </Wrapper>
  )
}
