import React from 'react'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Intro } from '../components/intro'
import { Container } from '../components/container'
import { Flex } from '../components/flex'

import contactImg from '../assets/contact.svg'

export default function ContactPage() {
  return (
    <Layout smallFooter={true}>
      <SEO title="Kontakt" />
      <Container>
        <Flex>
          <Flex.Item width={{ desktop: 8 }} offset={{ desktop: 2 }}>
            <Intro icon={contactImg}>Kontaktieren Sie uns</Intro>
            <strong>Gemeinde Vaduz</strong>
            <br />
            Lokal+Fair
            <br />
            9490 Vaduz
            <br />
            Telefon <a href="tel:+4232377878">+423 237 78 78</a>
            <br />
            <a href="mailto:lokalundfair@vaduz.li">lokalundfair@vaduz.li</a>
          </Flex.Item>
        </Flex>
      </Container>
    </Layout>
  )
}
